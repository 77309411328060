import {ChangeDetectionStrategy, Component, inject} from '@angular/core';
import {AuthenticationService} from "../../../../../core/services/authentication.service";
import {Member} from "../../../../../core/models/member.model";

@Component({
  templateUrl: './admin-menu-wrapper.component.html',
  styleUrls: ['./admin-menu-wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdminMenuWrapperComponent {

  readonly #authentService = inject(AuthenticationService);
  private readonly _memberConnected: Member | null = null;

  constructor() {
    this._memberConnected = this.#authentService.member;
    if (this._memberConnected === null) {
      this.#authentService.logout();
    }
  }

  get memberConnected(): Member | null {
    return this._memberConnected;
  }

}
