import {Component, inject} from '@angular/core';
import {Router} from "@angular/router";
import {AuthenticationService} from "./core/services/authentication.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  readonly #authenticationService = inject(AuthenticationService);
  readonly #router = inject(Router);

  constructor() {
    if (this.#authenticationService.isAuthenticated && this.#authenticationService.isAdmin) {
      this.#router.navigate(['/admin'])
    }
  }
}
