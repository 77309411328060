import {Component, Input} from "@angular/core";
import {Community} from "../../../../../core/models/community.model";

@Component({
  selector: 'app-delivery-tracking',
  templateUrl: './delivery-tracking.component.html',
  styleUrls: ['./delivery-tracking.component.scss']
})
export class DeliveryTrackingComponent {

  private _community!: Community;

  get community(): Community {
    return this._community;
  }

  @Input({required: true})
  set community(value: Community) {
    this._community = value;
  }
}
