import {Injectable} from "@angular/core";
import {environment} from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class ApiRoutesService {
  readonly apiBaseUrl = environment.apiBase + '/api';

  members(isAdmin = false) {
    return `${this.apiBaseUrl}${isAdmin ? '/admin' : ''}/members`;
  }

  hardware(isAdmin = false) {
    return `${this.apiBaseUrl}${isAdmin ? '/admin' : ''}/hardwares`;
  }

  installation(isAdmin = false) {
    return `${this.apiBaseUrl}${isAdmin ? '/admin' : ''}/installations`;
  }

  community(isAdmin = false) {
    return `${this.apiBaseUrl}${isAdmin ? '/admin' : ''}/communities`;
  }

  subscription(isAdmin = false) {
    return `${this.apiBaseUrl}${isAdmin ? '/admin' : ''}/subscriptions`;
  }

  addon(isAdmin = false) {
    return `${this.apiBaseUrl}${isAdmin ? '/admin' : ''}/addons`;
  }

  faq(isAdmin = false) {
    return `${this.apiBaseUrl}${isAdmin ? '/admin' : ''}/faqs`;
  }

  document(isAdmin = false) {
    return `${this.apiBaseUrl}${isAdmin ? '/admin' : ''}/documents`;
  }

  tip(isAdmin = false) {
    return `${this.apiBaseUrl}${isAdmin ? '/admin' : ''}/tips`;
  }

  tutorial(isAdmin = false) {
    return `${this.apiBaseUrl}${isAdmin ? '/admin' : ''}/tutorials`;
  }

  contact() {
    return `${this.apiBaseUrl}/contacts`;
  }

  me() {
    return `${this.apiBaseUrl}/me`;
  }

  login() {
    return `${this.apiBaseUrl}/auth/login/member`;
  }

  register() {
    return `${this.apiBaseUrl}/register`;
  }

  communityMember(isAdmin = false) {
    return `${this.apiBaseUrl}${isAdmin ? '/admin' : ''}/communities-members`;
  }

  jitsiRoomToken(rpi: string) {
    return `${this.apiBaseUrl}/jitsi/${rpi}`;
  }

  payments() {
    return `${this.apiBaseUrl}/payments`;
  }

  guarantees() {
    return `${this.apiBaseUrl}/admin/stats/guarantees`;
  }
}
