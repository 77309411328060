import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from "@angular/core";

@Component({
  selector: 'app-manage-picture',
  templateUrl: './manage-picture.component.html',
  styleUrls: ['./manage-picture.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class ManagePictureComponent {

  @Output() fileSelected: EventEmitter<File | null> = new EventEmitter<File | null>();


  private _pictureUrl: string | null = null;
  private _file: File | null = null;
  private _defaultPicture: boolean = false;
  filePreviewUrl: string | ArrayBuffer | null = null;

  get pictureUrl(): string | null {
    return this._pictureUrl;
  }

  @Input({required: true})
  set pictureUrl(value: string) {
    this._pictureUrl = value;
  }

  get defaultPicture(): boolean {
    return this._defaultPicture;
  }

  setDefaultPicture() {
    this._pictureUrl = '/assets/images/default-profile-picture.png'
    this._defaultPicture = true;
  }

  onFileSelected(selectedFileEvent: any) {
    this._file = selectedFileEvent.target.files[0];
    this.fileSelected.emit(this._file);

    if (this._file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.filePreviewUrl = e.target?.result ?? null;
      };
      reader.readAsDataURL(this._file);
    } else {
      this.filePreviewUrl = null;
    }
  }

  get file(): File | null {
    return this._file;
  }

  public clearFile() {
    this._file = null;
    this.fileSelected.emit(this._file);
  }

}
