import {ChangeDetectionStrategy, Component, EventEmitter, Output} from "@angular/core";
import {debounceTime, Subject} from "rxjs";

@Component({
  selector: 'app-admin-search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchInputComponent {
  @Output() search: EventEmitter<string> = new EventEmitter<string>();
  private _searchSubject: Subject<string> = new Subject<string>();

  constructor() {
    this._searchSubject.pipe(debounceTime(300)).subscribe(value => {
      this.search.emit(value);
    });
  }

  onInput(event: Event): void {
    const inputValue = (event.target as HTMLInputElement).value;
    this._searchSubject.next(inputValue);
  }
}
