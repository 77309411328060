<div class="search-input-component">
  <mat-form-field>
    <input matInput
           type="text"
           (input)="onInput($event)"
           placeholder="Rechercher..."
    >
    <img matSuffix ngSrc="assets/images/icons/search.svg" alt="Icon search" height="20" width="20">
  </mat-form-field>
</div>
