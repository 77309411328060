import {inject, Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {Member} from "../../models/member.model";
import {ApiRoutesService} from '../api-routes.service';

@Injectable({
  providedIn: 'root'
})
export class MemberService {

  readonly #httpClient = inject(HttpClient);
  readonly #routes = inject(ApiRoutesService);

  public me(): Observable<Member> {
    return this.#httpClient.get<Member>(this.#routes.me());
  }

  public findByCommunity(communityId: string): Observable<Member[]> {
    return this.#httpClient.get<Member[]>(`${this.#routes.community()}/${communityId}/members`);
  }
}
