import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from "@angular/core";

@Component({
  selector: 'app-admin-page-header',
  templateUrl: './admin-page-header.component.html',
  styleUrls: ['./admin-page-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdminPageHeaderComponent {

  @Output() buttonClicked: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();

  private _title: string = "";
  private _subtitle: string = "";
  private _buttonContent: string | null = null;
  private _isNoticeHeader: boolean = false;


  get title(): string {
    return this._title;
  }

  @Input({required: true})
  set title(value: string) {
    this._title = value;
  }

  get subtitle(): string {
    return this._subtitle;
  }

  @Input({required: true})
  set subtitle(value: string) {
    this._subtitle = value;
  }

  get buttonContent(): string | null {
    return this._buttonContent;
  }

  @Input()
  set buttonContent(value: string) {
    this._buttonContent = value;
  }

  @Input()
  get isNoticeHeader(): boolean {
    return this._isNoticeHeader;
  }

  set isNoticeHeader(value: boolean) {
    this._isNoticeHeader = value;
  }

  addItem(event: MouseEvent) {
    this.buttonClicked.emit(event);
  }
}
