import {APP_INITIALIZER, LOCALE_ID, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from "@angular/common/http";
import {MatSnackBarModule} from "@angular/material/snack-bar";
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from "@angular/material/form-field";
import {ApiAuthentInterceptor} from './core/interceptors/api-authent.interceptor';
import {AuthenticationService} from './core/services/authentication.service';
import {catchError, of} from 'rxjs';
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
import {registerLocaleData} from "@angular/common";
import localeFr from '@angular/common/locales/fr';
import {AdminSharedModule} from "./modules/admin/shared/admin-shared.module";

registerLocaleData(localeFr);

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MatSnackBarModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      },
      defaultLanguage: 'fr',
      useDefaultLang: true
    }),
    AdminSharedModule
  ],
  providers: [
    {
      provide: LOCALE_ID, useValue: 'fr'
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue:
        {
          appearance: 'outline',
          color: 'accent',
          subscriptSizing: 'dynamic',
        }
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiAuthentInterceptor,
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeAppFactory,
      deps: [AuthenticationService],
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}

export function initializeAppFactory(authentService: AuthenticationService) {
  return () => authentService.initializeApp().pipe(
    catchError(() => of(null))
  );
}

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
