<div class="admin-page-header-component" [ngClass]=" buttonContent ? 'justify-between' : 'justify-start'">

  <div class="container-title">
    <h1>{{title}}</h1>
    <div class="content">{{subtitle}}</div>
  </div>

  <button mat-flat-button (click)="addItem($event)" *ngIf="buttonContent"
          class="size-xl"
          color="accent"
          type="button">
    <div class="icon">
      <img ngSrc="assets/images/icons/white/add.svg" alt="Icon add" width="24" height="24" *ngIf="!isNoticeHeader">
      <img ngSrc="assets/images/icons/white/edit.svg" alt="Icon edit" width="24" height="24" *ngIf="isNoticeHeader && buttonContent === 'Modifier'">
      <img ngSrc="assets/images/icons/white/save.svg" alt="Icon save" width="24" height="24" *ngIf="isNoticeHeader && buttonContent === 'Sauvegarder'">
      {{ buttonContent }}
    </div>
  </button>

</div>
