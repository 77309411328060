<div class="table-skeleton-component pt-0.5">
  <div class="animate-pulse bg-white h-12 w-full rounded-xl my-3"></div>

  <div class="animate-pulse bg-white h-16 w-full rounded-xl my-3"></div>
  <div class="animate-pulse bg-white h-16 w-full rounded-xl my-3"></div>
  <div class="animate-pulse bg-white h-16 w-full rounded-xl my-3"></div>
  <div class="animate-pulse bg-white h-16 w-full rounded-xl my-3"></div>
  <div class="animate-pulse bg-white h-16 w-full rounded-xl my-3"></div>

  <div class="flex justify-center gap-4">
    <div class="animate-pulse bg-white rounded-lg h-10 w-10"></div>
    <div class="animate-pulse bg-white rounded-lg h-10 w-10"></div>
    <div class="animate-pulse bg-white rounded-lg h-10 w-10"></div>
  </div>
</div>
