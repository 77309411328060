<div [hidden]="!isDisabled">
  <div [innerHTML]="safetyHTML" class="rounded-2xl bg-white p-6"></div>
</div>
<div [hidden]="isDisabled">
  <editor
    [apiKey]="tinyMceApiKey"
    [(ngModel)]="text"
    [disabled]="isDisabled"
    [init]="{
        height: 730,
        menubar: false,
        resize: false,
        plugins:'link lists',
        toolbar:
          'undo redo |fontfamily fontsize | formatselect| title | bold italic underline forecolor backcolor link  | quicklink blockquote | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat',
        toolbar_groups: {
          title: {
            icon: 'format',
            tooltip: 'Titre',
            items: 'h1 h2 h3 h4 h5',
          },
        },
    }">
  </editor>
</div>


