import {ChangeDetectionStrategy, Component} from '@angular/core';

@Component({
  selector: 'app-pages-skeleton',
  templateUrl: './pages-skeleton.component.html',
  styleUrls: ['./pages-skeleton.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PagesSkeletonComponent {

}
