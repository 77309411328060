import {CanActivateFn} from "@angular/router";
import {inject} from "@angular/core";
import {AuthenticationService} from "../services/authentication.service";

export function isAuthenticatedGuard(): CanActivateFn {
  return (route, state) => {
    const authenticationService = inject(AuthenticationService);

    if (authenticationService.isAuthenticated) {
      return true;
    } else {
      authenticationService.logout();
      return false;
    }
  };
}
