import {Component, inject, Input, ViewEncapsulation} from '@angular/core';
import {DomSanitizer} from "@angular/platform-browser";
import {environment} from "../../../../../../environments/environment";

@Component({
  selector: 'app-notice-editor',
  templateUrl: './document-editor.component.html',
  styleUrls: ['./document-editor.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DocumentEditorComponent {

  readonly #sanitizer:DomSanitizer = inject(DomSanitizer);

  readonly #tinyMceApiKey = environment.tinyMceApiKey;

  private _text: string = '';
  private _isDisabled: boolean = true

  get tinyMceApiKey(): string {
    return this.#tinyMceApiKey;
  }

  get text(): string {
    return  this._text;
  }

  get safetyHTML(): any {
    return this.#sanitizer.bypassSecurityTrustHtml(this._text);
  }

  @Input({required: true})
  set text(value: string) {
    this._text = value;
  }

  get isDisabled(): boolean {
    return this._isDisabled;
  }

  @Input({required: true})
  set isDisabled(value: boolean) {
    this._isDisabled = value;
  }

}
