import {NgModule} from '@angular/core';
import {AdminMenuWrapperComponent} from "./wrappers/admin-menu-wrapper/admin-menu-wrapper.component";
import {CommonModule, NgOptimizedImage} from "@angular/common";
import {AdminSharedRoutingModule} from "./admin-shared-routing.module";
import {AdminMenuDesktopComponent} from "./components/admin-menu-desktop/admin-menu-desktop.component";
import {AdminPageHeaderComponent} from "./components/admin-page-header/admin-page-header.component";
import {MatButtonModule} from "@angular/material/button";
import {SearchInputComponent} from "./components/search-input/search-input.component";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {TableSkeletonComponent} from "./components/table-skeleton/table-skeleton.component";
import {
  AdminMenuSectionComponent
} from "./components/admin-menu-desktop/admin-menu-section/admin-menu-section.component";
import {MatSnackBarModule} from "@angular/material/snack-bar";
import {ManagePictureComponent} from "./components/manage-picture/manage-picture.component";
import {EditorComponent} from "@tinymce/tinymce-angular";
import {DeliveryTrackingComponent} from "./components/delivery-tracking/delivery-tracking.component";
import {DocumentEditorComponent} from "./components/document-editor/document-editor.component";
import {PagesSkeletonComponent} from './components/pages-skeleton/pages-skeleton.component';


@NgModule({
  declarations: [
    AdminMenuWrapperComponent,
    AdminMenuSectionComponent,
    AdminMenuDesktopComponent,
    AdminPageHeaderComponent,
    SearchInputComponent,
    TableSkeletonComponent,
    ManagePictureComponent,
    DeliveryTrackingComponent,
    DocumentEditorComponent,
    DeliveryTrackingComponent,
    DeliveryTrackingComponent,
    PagesSkeletonComponent,
  ],
  exports: [
    AdminPageHeaderComponent,
    SearchInputComponent,
    TableSkeletonComponent,
    ManagePictureComponent,
    DocumentEditorComponent,
    DeliveryTrackingComponent,
    PagesSkeletonComponent
  ],
  imports: [
    CommonModule,
    AdminSharedRoutingModule,
    NgOptimizedImage,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    MatSnackBarModule,
    EditorComponent,
    FormsModule
  ],
})
export class AdminSharedModule {
}
