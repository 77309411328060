<div id="admin-menu-wrapper">

  <div class="container-admin-menu">
    <app-admin-menu-desktop [memberConnected]="memberConnected"></app-admin-menu-desktop>
  </div>

  <div class="container-content">
    <router-outlet></router-outlet>
  </div>
</div>
