<div class="manage-picture-component">
  <div class="container-upload-picture">
    <img *ngIf="pictureUrl && !file" (error)="setDefaultPicture()" [src]="pictureUrl" alt="Image de l'astuce">
    <img *ngIf="!pictureUrl && !file" src="assets/images/default-profile-picture.png" alt="default picture">
    <img *ngIf="file" [src]="filePreviewUrl" alt="file preview">
  </div>
  <button mat-stroked-button (click)="inputFile.click()"
          class="full-width btn-stroked-primary btn-upload mb-4"
          color="primary"
          type="button">
    <div class="icon">
      <ng-container *ngIf="file || !defaultPicture">
        <img ngSrc="assets/images/icons/blue/edit.svg" alt="Icon edit" width="20" height="20">
        Modifier la photo
      </ng-container>
      <ng-container *ngIf="!file && defaultPicture">
        <img ngSrc="assets/images/icons/blue/upload.svg" alt="Icon upload" width="20" height="20">
        Importer une photo
      </ng-container>
    </div>
  </button>
  <button mat-stroked-button (click)="clearFile()" *ngIf="file"
          class="full-width size-xs"
          color="warn"
          type="button">
    <div class="icon">
      <img ngSrc="assets/images/icons/error/trash.svg" alt="Icon trash" width="20" height="20"> Retirer la photo
    </div>
  </button>
  <input #inputFile type='file' (change)='onFileSelected($event)' class='hidden' accept="image/png, image/jpeg">
</div>
