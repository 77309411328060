<div id="admin-menu-desktop-component">

  <div>
    <div class="logo">
      <img ngSrc="/assets/images/coucou_visio_logo.png" alt="logo" height="70" width="150" priority="high">
    </div>

    <ng-container *ngFor="let section of adminMenuSections">
      <app-admin-menu-section [section]="section"></app-admin-menu-section>
    </ng-container>
  </div>

  <div class="container-admin-profile" *ngIf="memberConnected">
    <div class="content">
      <div class="profile-circle">
        {{ getInitials(memberConnected.profile.firstName, memberConnected.profile.lastName) }}
      </div>
      <div class="info">
        <div class="name">{{ memberConnected.profile.firstName }} {{ memberConnected.profile.lastName }}</div>
        <div class="email">{{ memberConnected.email }}</div>
      </div>
    </div>
    <img (click)="logout()" ngSrc="/assets/images/icons/logout.svg" alt="Icon logout" height="20" width="20">
  </div>
</div>
