import {Component, inject, Input} from "@angular/core";
import {AdminMenuSection} from "../../../../../../core/models/admin-menu-item.model";
import {AuthenticationService} from "../../../../../../core/services/authentication.service";

@Component({
  selector: 'app-admin-menu-section',
  templateUrl: './admin-menu-section.component.html',
  styleUrls: ['./admin-menu-section.component.scss']
})
export class AdminMenuSectionComponent {

  private _section!: AdminMenuSection;
  readonly #memberConnected = inject(AuthenticationService).member;

  get section(): AdminMenuSection {
    return this._section;
  }

  @Input({required: true})
  set section(value: AdminMenuSection) {
    this._section = value;
  }

  public hasUserAuthorization(): boolean {
    if (!this.#memberConnected) {
      return false
    } else {
      return this.#memberConnected?.roles.some(userRole => this._section.roles.includes(userRole.name));
    }
  }
}
