import {RouterModule, Routes} from '@angular/router';
import {AdminMenuWrapperComponent} from "./wrappers/admin-menu-wrapper/admin-menu-wrapper.component";
import {NgModule} from "@angular/core";
import {hasRoleGuard} from "../../../core/guards/role.guard";

export const ADMIN_WRAPPER_ROUTES: Routes = [
  {
    path: '',
    component: AdminMenuWrapperComponent,
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('../dashboard/dashboard.module').then(m => m.DashboardModule),
        canActivate: [hasRoleGuard(['ROLE_INSTALLER', 'ROLE_CLIENT_ADMIN'])],
        title: `Back-office | Dashboard`
      },
      {
        path: 'orders',
        loadChildren: () => import('../orders/orders.module').then(m => m.OrdersModule),
        canActivate: [hasRoleGuard(['ROLE_INSTALLER', 'ROLE_CLIENT_ADMIN'])],
        title: `Back-office | Les commandes`
      },
      {
        path: 'installations',
        loadChildren: () => import('../installations/installations.module').then(m => m.InstallationsModule),
        canActivate: [hasRoleGuard(['ROLE_INSTALLER', 'ROLE_CLIENT_ADMIN'])],
        title: `Back-office | Les installations`
      },
      {
        path: 'hardwares',
        loadChildren: () => import('../hardwares/hardwares.module').then(m => m.HardwaresModule),
        canActivate: [hasRoleGuard(['ROLE_INSTALLER', 'ROLE_CLIENT_ADMIN'])],
        title: `Back-office | Les matériels`
      },
      {
        path: 'customers',
        loadChildren: () => import('../members/members.module').then(m => m.MembersModule),
        canActivate: [hasRoleGuard(['ROLE_CLIENT_ADMIN'])],
        title: `Back-office | Les utilisateurs`
      },
      {
        path: 'associations',
        loadChildren: () => import('../associations/associations.module').then(m => m.AssociationsModule),
        canActivate: [hasRoleGuard(['ROLE_CLIENT_ADMIN'])],
        title: `Back-office | Les associations`
      },
      {
        path: 'admins',
        loadChildren: () => import('../admins/admins.module').then(m => m.AdminsModule),
        canActivate: [hasRoleGuard(['ROLE_CLIENT_ADMIN'])],
        title: `Back-office | Les administrateurs & installateurs`
      },
      {
        path: 'subscriptions',
        loadChildren: () => import('../subscriptions/subscription.module').then(m => m.SubscriptionModule),
        canActivate: [hasRoleGuard(['ROLE_CLIENT_ADMIN'])],
        title: `Back-office | Les abonnements`
      },
      {
        path: 'options',
        loadChildren: () => import('../addons/addons.module').then(m => m.AddonsModule),
        canActivate: [hasRoleGuard(['ROLE_CLIENT_ADMIN'])],
        title: `Back-office | Les options d'abonnement`
      },
      {
        path: 'faq',
        loadChildren: () => import('../faqs/faqs.module').then(m => m.FaqsModule),
        canActivate: [hasRoleGuard(['ROLE_CLIENT_ADMIN'])],
        title: `Back-office | Foire aux questions`
      },
      {
        path: 'tips',
        loadChildren: () => import('../tips/tips.module').then(m => m.TipsModule),
        canActivate: [hasRoleGuard(['ROLE_CLIENT_ADMIN'])],
        title: `Back-office | Les astuces`
      },
      {
        path: 'notice',
        loadChildren: () => import('../notice/notice.module').then(m => m.NoticeModule),
        canActivate: [hasRoleGuard(['ROLE_CLIENT_ADMIN'])],
        title: `Back-office | Mentions légales`
      },
      {
        path: 'guides',
        loadChildren: () => import('../tutorials/tutorials.module').then(m => m.TutorialsModule),
        canActivate: [hasRoleGuard(['ROLE_CLIENT_ADMIN'])],
      },
      {
        path: 'legal-information',
        loadChildren: () => import('../associations/associations.module').then(m => m.AssociationsModule),
        canActivate: [hasRoleGuard(['ROLE_CLIENT_ADMIN'])],
        title: `Back-office | Les guides & tutoriels`
      },
      {
        path: 'privacy-policy',
        loadChildren: () => import('../policy/policy.module').then(m => m.PolicyModule),
        canActivate: [hasRoleGuard(['ROLE_CLIENT_ADMIN'])],
        title: `Back-office | Politique de confidentialité`
      },
      {
        path: '**',
        redirectTo: 'dashboard'
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(ADMIN_WRAPPER_ROUTES)],
  exports: [RouterModule]
})
export class AdminSharedRoutingModule {
}
