import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {isAuthenticatedGuard} from "./core/guards/authentication.guard";
import {hasRoleGuard} from "./core/guards/role.guard";

const routes: Routes = [
  {
    path: 'public',
    loadChildren: () => import('./modules/public/public.module').then(m => m.PublicModule)
  },
  {
    path: 'app',
    loadChildren: () => import('./modules/connected/connected.module').then(m => m.ConnectedModule),
    canActivate: [isAuthenticatedGuard, hasRoleGuard(['ROLE_USER', 'ROLE_BUSINESS'])],
  },
  {
    path: 'admin',
    loadChildren: () => import('./modules/admin/shared/admin-shared.module').then(m => m.AdminSharedModule),
    canActivate: [isAuthenticatedGuard, hasRoleGuard(['ROLE_INSTALLER', 'ROLE_CLIENT_ADMIN'])],
  },
  {
    path: '**',
    redirectTo: 'public',
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {initialNavigation: 'enabledBlocking', paramsInheritanceStrategy: 'always'})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
