import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import {catchError, Observable, throwError} from 'rxjs';
import {inject, Injectable} from '@angular/core';
import {AuthenticationService} from '../services/authentication.service';

@Injectable()
export class ApiAuthentInterceptor implements HttpInterceptor {

  readonly #authenticationService = inject(AuthenticationService);

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const authorizationHeaders: HttpHeaders = req.headers.set('Authorization', 'Bearer ' + this.#authenticationService.accessToken);
    const requestUpdate = this.#authenticationService.isAuthenticated ? {headers: authorizationHeaders} : {}

    const request = req.clone(requestUpdate);

    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => this.handleErrorRes(error))
    );
  }

  private handleErrorRes(error: HttpErrorResponse): Observable<never> {
    switch (error.status) {
      case 401:
        this.#authenticationService.logout();
        break;
    }
    return throwError(() => error);
  }

}
