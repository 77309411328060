import {CanActivateFn} from "@angular/router";
import {inject} from "@angular/core";
import {AuthenticationService} from "../services/authentication.service";

export function hasRoleGuard(roles: string[]): CanActivateFn {
  return (route, state) => {
    const authenticationService = inject(AuthenticationService);
    const userRoles: string[] = authenticationService.roles;

    return userRoles.some(userRole => roles.includes(userRole));
  };
}
