<div id="delivery-tracking-component" *ngIf="community">

  <div class="address-delivery-block">
    <img ngSrc="../../../../../../assets/images/icons/user.svg" alt="Icon user" height="24" width="24">
    <div>
      <div class="subtitle">Nom du propriétaire</div>
      <div class="info">{{community.admins[0].firstName | titlecase}} {{community.admins[0].lastName | titlecase}}</div>
    </div>
  </div>

  <div class="address-delivery-block">
    <img ngSrc="assets/images/icons/phone.svg" alt="Icon phone" height="24" width="24">
    <div>
      <div class="subtitle">numéro de téléphone du proche</div>
      <div class="info">{{ community.relative.phone }}</div>
    </div>
  </div>

  <div class="address-delivery-block">
    <img ngSrc="assets/images/icons/map-point.svg" alt="Icon map point" height="24" width="24">
    <div>
      <div class="subtitle">adresse</div>
      <div class="info">{{ community.addressInstallation.street }}</div>
    </div>
  </div>

  <div class="address-delivery-block">
    <img ngSrc="assets/images/icons/postal-code.svg" alt="Icon postal code" height="24" width="24">
    <div>
      <div class="subtitle">Code postal</div>
      <div class="info">{{community.addressInstallation.zipCode}}</div>
    </div>
  </div>

  <div class="address-delivery-block">
    <img ngSrc="assets/images/icons/city.svg" alt="Icon city" height="24" width="24">
    <div>
      <div class="subtitle">ville</div>
      <div class="info">{{ community.addressInstallation.city }}</div>
    </div>
  </div>

  <div class="address-delivery-block" *ngIf="community.addressInstallation.infos">
    <img ngSrc="assets/images/icons/info-circle.svg" alt="Icon info circle" height="24" width="24">
    <div>
      <div class="subtitle">Informations complémentaires</div>
      <div class="info">{{ community.addressInstallation.infos }}</div>
    </div>
  </div>
</div>
